import {type MetaFunction} from '@remix-run/node'
import {ChatBubbleBottomCenterTextIcon, CloudArrowUpIcon, ComputerDesktopIcon} from '@heroicons/react/20/solid'

export const meta: MetaFunction = () => [{title: 'refundr'}]

const dashboardFeatures = [
    {
        name: 'Paper-less returns made easy',
        description:
            'Viewing, downloading, and even printing incoming client documents has been designed by other tax consultants.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Dashboard',
        description: 'Quickly view the state of a client\'s tax return from new customer to Efiled. Our tax consultant managed dashboard allows you to be in control of each return\'s progress.',
        icon: ComputerDesktopIcon,
    },
    {
        name: 'Communication',
        description: 'Exchange messages, agent documents and tax returns results for Canadian and U.S. clients at your convenience.',
        icon: ChatBubbleBottomCenterTextIcon,
    },
]

const clientFeatures = [
    {
        name: 'Completed Returns and Digital Signatures',
        description: 'Your time is not required to complete a Canadian or U.S. return. Client can sign and pay you for your services. You are notified to EFile when steps are completed.',
        icon: ComputerDesktopIcon,
    },
    {
        name: 'Profile and Document Management',
        description:
            'Clients know what is required from you and can organize all documents by category.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Communication',
        description: 'Clients can message you at their convenience making clearer communication at the heart of your tax business year-over-year.',
        icon: ChatBubbleBottomCenterTextIcon,
    },
]

export default function Index() {
    return (
        <div>
            <div className="relative isolate overflow-hidden bg-[#00AFAA]">
                <div className="mx-auto max-w-7xl px-6 py-10 lg:px-8">
                    <div
                        className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">

                        <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <div className="font-bold font-mono text-gray-900">Tax return and client management
                                software
                            </div>
                            <h1 className="max-w-2xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto py-7">
                                Optimize your tax season<br/>Save up to 10%* of your revenue
                            </h1>
                            <div className="text-xl leading-8 text-gray-900">
                                <i>Built by tax consultants for tax consultants.</i></div>
                            <div className="text-black pt-3">
                                Create return results in minutes, streamline customer data and offer clients a better
                                experience. Partnered consultants have been excited to compliment refundr in their current workflow.
                            </div>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="/book"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Book demo
                                </a>

                                or

                                <a
                                    href="https://dashboard.refundr.ca/signup"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    target="_blank"
                                >
                                    Try for free in minutes
                                </a>
                                {/*<a href="#" className="text-sm font-semibold leading-6 text-gray-900">*/}
                                {/*    Learn more <span aria-hidden="true">→</span>*/}
                                {/*</a>*/}
                            </div>
                            <div className="text-xs mt-8 text-gray-900">* based on real results from refundr partnered
                                consultants
                            </div>
                        </div>
                        <img
                            src="/img/tax-season.jpg"
                            alt=""
                            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 rounded-xl"
                        />

                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 sm:h-32"/>
            </div>
            <div className="overflow-hidden bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                        <div className="lg:pr-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Take on more clients
                                    with less workload</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better
                                    workflow</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    As you know, tax season is a compressed time of the year, both consultants and their
                                    clients find themselves grappling with a chaotic workload.
                                    After handling thousands of returns and collaborating closely with consultants over
                                    the past four years, refundr is proud to announce its expansion, now available to
                                    consultants across the country.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {dashboardFeatures.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                              aria-hidden="true"/>
                                                {feature.name}
                                            </dt>
                                            {' '}
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <img
                            src="/img/refundr-dashboard5.png"
                            alt="Product screenshot"
                            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[80rem] md:-ml-4 lg:ml-0 border border-gray-200"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
            <div className="overflow-hidden bg-white">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">thousands of clients
                                    approve</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Happier clients</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Designed by tax consultants, our award winning design brings the client/consultant
                                    exchange to another level year-over-year.
                                    Consultants can be sure clients can access their documents and your clients know
                                    where to find them.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {clientFeatures.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                              aria-hidden="true"/>
                                                {feature.name}
                                            </dt>
                                            {' '}
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="flex  items-start justify-end lg:order-first">
                            <img
                                src="/img/refundr-web2.png"
                                alt="Product screenshot"
                                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[65rem] border border-gray-200"
                                width={2432}
                                height={1442}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative isolate bg-white">
                <div
                    className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                        <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Clients can work with you on the go
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            In these modern busy times, it is important your clients can access their data and messages
                            on the go.
                            Our award winning iPhone application has had hundreds of hours of client feedback to date.
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a
                                href="https://apps.apple.com/us/app/refundr/id1465178785?ls=1"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                target="_blank"
                            >
                                Get started
                            </a>
                            <a href="/client-stories" className="text-sm font-semibold leading-6 text-gray-900">
                                Learn more <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                    <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                        <svg viewBox="0 0 366 729" role="img"
                             className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
                            <title>App screenshot</title>
                            <defs>
                                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                                    <rect width={316} height={684} rx={36}/>
                                </clipPath>
                            </defs>
                            <path
                                fill="#4B5563"
                                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                            />
                            <path
                                fill="#343E4E"
                                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                            />
                            <foreignObject
                                width={316}
                                height={684}
                                transform="translate(24 24)"
                                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                            >
                                <img src="/img/try1.png" className="relative top-32" alt=""/>
                            </foreignObject>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

    )
}
